<template>
  <div class="row">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label for="name">Nazwa</label>
          <input
            id="name"
            class="form-control"
            v-model="value.label"
            @input="updateLabel"
          >
        </div>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`start-place-${itemIndex}`">Stan początkowy</label>
          <select
            :id="`start-place-${itemIndex}`"
            class="form-control"
            v-model="value.fromStates[0].id"
            @input="updateStart"
          >
            <option
              v-for="option in fromStates"
              :value="option.id"
              :key="`from-${option.name}`"
            >
              {{ option.label }}
            </option>
          </select>
        </form>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`end-place-${itemIndex}`">Stan końcowy</label>
          <select
            :id="`end-place-${itemIndex}`"
            class="form-control"
            v-model="value.toStates[0].id"
            @input="updateEnd"
          >
            <option
              v-for="option in toStates"
              :value="option.id"
              :key="`to-${option.name}`"
            >
              {{ option.label }}
            </option>
          </select>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`role-select-${itemIndex}`">Role:</label>
          <select
            :id="`role-select-${itemIndex}`"
            name="roles[]"
            :multiple="true"
            v-model="value.accessRoles"
            @input="updateRoles"
          >
            <template v-for="(role) in roles">
              <option
                :value="role"
                :key="`${itemIndex}-role-${role.id}`">
                {{role.label}}
              </option>
            </template>
          </select>
          <div v-if="roles.length === 0">
            <label>Brak ról do przypisania</label>
          </div>
        </form>
        <div
          class="form-group"
          :class="{'has-error': vErrors.has('taskCategory') }"
        >
          <label>{{ vErrors.first('taskCategory') }}</label>
        </div>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`validation-${itemIndex}`">Walidacje:</label>
          <select
            :id="`validation-${itemIndex}`"
            name="validations[]"
            multiple
            v-model="value.metadata['validations']"
            @input="updateConfiguration"
          >
            <template v-for="(optionValue, optionKey) in configuration.validations">
            <option
              :value="optionKey"
              :key="optionKey"
            > {{optionValue}}
            </option>
          </template>
          </select>
        </form>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`notification-${itemIndex}`">Powiadomienia:</label>
          <select
            :id="`notification-${itemIndex}`"
            name="notifications[]"
            multiple
            v-model="value.metadata['notifications']"
            @input="updateConfiguration"
          >
          <template v-for="(optionValue, optionKey) in configuration.notifications">
            <option
              :value="optionKey"
              :key="optionKey"
            > {{optionValue}}
            </option>
          </template>
          </select>
        </form>
      </div>
      <div class="col-sm-4">
        <form class="flex-column">
          <label :for="`operation-${itemIndex}`">Zdarzenia:</label>
          <select
            :id="`operation-${itemIndex}`"
            name="operations[]"
            multiple
            v-model="value.metadata['operations']"
            @input="updateConfiguration"
          >
          <template v-for="(optionValue, optionKey) in configuration.operations">
            <option
              :value="optionKey"
              :key="optionKey"
            > {{optionValue}}
            </option>
          </template>
          </select>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemIndex: {type: Number},
    value: {type: Object},
    fromStates: { type: Array, required: true },
    toStates: { type: Array, required: true },
    roles: {type: Array, required: true, default: () => []},
    configuration: {type: Object, required: true, default: () => {}}
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateLabel ($event) {
      this.value.label = $event.target.value
      this.value.name = $event.target.value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateStart ($event) {
      this.value.fromStates = [this.fromStates.find(el => el.id == $event.target.value)]
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateEnd ($event) {
      this.value.toStates = [this.toStates.find(el => el.id == $event.target.value)]
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateRoles (value) {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    },
    updateConfiguration (value) {
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>
