<template>
  <div>
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Przepływy</h2>
                    <list
                      :items="transitions"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <transition-type
                          v-model="listItems.item"
                          :roles="roles"
                          :from-states="statesObjects"
                          :to-states="statesObjects"
                          :itemIndex="listItems.index"
                          :configuration="configuration"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="`${service}_transition_listing`"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import Page from '../../../../share/content/Page'
import api from '../../../../../api'
import List from '../../../../share/form/List'
import LoginModal from '../../../share/modal/LoginModal'
import TransitionType from './TransitionType'
import SaveDataInformation from '../../../share/modal/SaveDataInformation'

export default {
  components: {
    TransitionType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: null,
      rolesLoaded: false,
      statesLoaded: false,
      transitionsLoaded: false,
      roles: [],
      states: [],
      headerTitle: {title: 'Mapa przepływu', description: 'Tryb edycji'},
      loginVisible: false,
      allowedAccess: false,
      isSaved: false,
      saveDataInformationVisible: false,
      previouslySelectedRole: null,
      service: this.$route.meta.acl.service,
      statesObjects: [],
      transitions: [],
      configuration: []
    }
  },

  computed: {
    roleId () {
      return this.roles.find(r => r.name === this.model.role).id
    }
  },
  created () {
    this.getInitEvents()
  },
  mounted () {
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.transitionSubmit, this.submitData)
    })
  },
  methods: {
    getInitEvents () {
      this.events = {
        transitionSubmit: `${this.service}:main:transitionSubmit`
      }
    },
    init () {
      this.allowedAccess = true
      this.getConfiguration()
      this.loadRoles()
      this.loadStates()
      this.loadTransitions()

    },
    createEmptyElement () {
      return {
        label: null,
        fromStates: [[]],
        toStates: [[]],
        accessRoles: [],
        metadata: {
          operations: [],
          notifications: [],
          validations: []
        }
      }
    },
    loadRoles () {
      api.request(this.service, 'get', '/roles')
        .then((response) => {
          const roles = response.data

          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({
              id: roles[c].id,
              label: roles[c].label + (roles[c].businessRole ? ' (rola biznesowa)' : ''),
              name: roles[c].name
            })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
      getConfiguration () {
      api.request(this.service, 'get', `/workflows/1/transitions/configuration`)
        .then((response) => {
          this.configuration = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować zdarzeń tranzycji'
          })
        })
    },
    loadStates () {
      api.request(this.service, 'get', `/workflows/1/states?format=raw`)
        .then((response) => {
          const states = response.data
          this.statesObjects = states
          for (let c = 0; c < states.length; ++c) {
            this.states.push({label: states[c].label, value: states[c].name})
          }
          this.statesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    forceToSave () {
      this.isSaved = false
    },
    loadTransitions () {
      api.request(this.service, 'get', `/workflows/1/transitions?format=admin-list`)
        .then((response) => {
          this.transitions = Array.isArray(response.data) ? response.data.map(el => {
            if(el.metadata === null || (Array.isArray(el.metadata) && el.metadata.length === 0) || el.metadata === undefined ) {
              el.metadata = {}
            }
            return el
          }) : []
          this.transitionsLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować przepływów'
          })
        })
    },
    submitData () {
      let submitData = {transitions: []}

      for (let c = 0; c < this.transitions.length; ++c) {
        submitData.transitions.push({
          id: this.transitions[c].id ? this.transitions[c].id : null,
          name: this.transitions[c].name,
          label: this.transitions[c].label,
          workflow: 'settlement',
          accessRoles: this.transitions[c].accessRoles,
          fromStates: [this.transitions[c].fromStates[0]],
          toStates: [this.transitions[c].toStates[0]],
          metadata: this.transitions[c].metadata,
        })
      }
      api.request(this.service, 'put', `/workflows/1/transitions`, submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 18px
}
</style>
